import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../Components/Layout";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import Section from "../common/Section/Section";
import {colors} from '../utils/styles';

const CardTextStyled = styled(Card.Text)`
  height: 90px !important;
`;

const links = ["コース", "体験"];

const TrialCard = props => {
  return (
    <Card bg="light" style={{ width: "100%", marginBottom: '20px' }}>
      <Card.Img variant="top" src={props.for_image_url} />
      <Card.Body>
        <Card.Title style= {{color: colors.sscBlue}}>{props.for_title}</Card.Title>
        <CardTextStyled>{props.for_description}</CardTextStyled>
        <Alert variant="danger">＊{props.for_warning}</Alert>
      </Card.Body>
    </Card>
  );
};

const TrialPage = props => {
  const data = useStaticQuery(graphql`
    {
      prismicTrialPage {
        id
        data {
          introduction_text
          for_adults_title
          for_adults_description
          for_adults_warning
          for_adults_image {
            url
          }
          for_children_title
          for_children_description
          for_children_warning
          for_children_image {
            url
          }
        }
      }
    }
  `);

  const {
    introduction_text,
    for_adults_title,
    for_adults_description,
    for_adults_warning,
    for_adults_image,
    for_children_title,
    for_children_description,
    for_children_warning,
    for_children_image,
  } = data.prismicTrialPage.data;

  return (
    <>
      <Layout
        links={links}
        title="Trial"
        subTitle="ワンコイン体験"
        seoTitle="ワンコイン体験"
        seoDescrption="狭山市でワンコインで水泳を体験しましょう！お待ちしております。"
        seoKeywords="ワンコイン体験,水泳,所沢市,狭山市、入間市"
      >
        <Section>
          <Container>
            <Alert variant="warning">{introduction_text}</Alert>
            <Row>
              <Col sm={6}>
                <TrialCard
                  for_image_url={for_children_image.url}
                  for_title={for_children_title}
                  for_description={for_children_description}
                  for_warning={for_children_warning}
                />
              </Col>
              <Col sm={6}>
                <TrialCard
                  for_image_url={for_adults_image.url}
                  for_title={for_adults_title}
                  for_description={for_adults_description}
                  for_warning={for_adults_warning}
                />
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default TrialPage;
